<template>
  <div class="goods">
    <left-tabs :tabList="leftList" />
     <router-view></router-view>
  </div>
</template>
<script>
import ContentTitle from "@/components/contentTitle";
import LeftTabs from "@/components/leftTabs";
export default {
  name: "goods",
  components: {
    ContentTitle,
    LeftTabs,
  },
  data() {
    return {
        leftList: [
          {id: 1, name: '商品列表', url: '/goods/list'},
          {id: 2, name: '商品分类', url: '/goods/classify'},
          {id: 3, name: '虚拟用户', url: '/goods/virtualUser'}
        ],
    };
  },
  methods: {
    
  },

};
</script>
<style scoped lang="less">
.goods {
  display: flex;
}

</style>